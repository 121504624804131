/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react'
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import TitleShadow from '../TitleShadow'
import CustomerLogo from '../CustomerLogo'

gsap.registerPlugin(ScrollTrigger)

const MyCustomers = () => {
  const focusContainerRef = useRef<HTMLDivElement>(null)
  useIsomorphicLayoutEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: focusContainerRef.current,
        start: '-50px center',
        end: 'top center',
        // markers: true,
        // toggleActions: "restart pause reverse pause",
        // scrub: 1, // scrub is use set the amount of time to catch up, it kinda looks the scroll animation with ur scroller
      },
    })
    titleTl
      .addLabel('start')
      .from(focusContainerRef.current, { opacity: 0, translateX: '-100px' })
      .addLabel('entering')
      .to(focusContainerRef.current, { opacity: 1, translateX: '0px', duration: 0.5 })
      .addLabel('end')
    gsap.set('.customer-item', { opacity: 0, y: 20 })
    ScrollTrigger.batch('.customer-item', {
      start: '-160px center',
      end: 'top center',
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15 }),
    })
  }, [])

  return (
    <div className='section-customer py-24'>
      <div className='container mx-auto max-w-screen-3xl px-6 pt-28' ref={focusContainerRef}>
        <div className='mb-16'>
          <TitleShadow
            prologue='ขอบคุณลูกค้าของเรา'
            colorPrologue='#EFEFEF'
            title='ขอบคุณลูกค้าของเรา'
            colorTitle='#000000'
            subtitle='MY Customers'
            colorSubtitle='#000000'
            align='center'
          />
        </div>
        <div className='grid w-full grid-cols-3 gap-8 text-center lg:grid-cols-5 lg:text-left'>
          <CustomerLogo
            imgSrc='/images/customer/01_atime.webp'
            imgSrcHover='/images/customer/01_atime_hover.webp'
            imgAlt='Atime'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/02_central.webp'
            imgSrcHover='/images/customer/02_central_hover.webp'
            imgAlt='Central'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/03_cmg.webp'
            imgSrcHover='/images/customer/03_cmg_hover.webp'
            imgAlt='CMG'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/04_or.webp'
            imgSrcHover='/images/customer/04_or_hover.webp'
            imgAlt='CMG'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/05_scg.webp'
            imgSrcHover='/images/customer/05_scg_hover.webp'
            imgAlt='SCG'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/06_srichan.webp'
            imgSrcHover='/images/customer/06_srichan_hover.webp'
            imgAlt='Srichan'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/07_spicy_disc.webp'
            imgSrcHover='/images/customer/07_spicy_disc_hover.webp'
            imgAlt='Spicy Disc'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/08_brandthink.webp'
            imgSrcHover='/images/customer/08_brandthink_hover.webp'
            imgAlt='Brandthink'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/09_indorama.webp'
            imgSrcHover='/images/customer/09_indorama_hover.webp'
            imgAlt='Indorama'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/10_willpower.webp'
            imgSrcHover='/images/customer/10_willpower_hover.webp'
            imgAlt='Will Power'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/11_kpi.webp'
            imgSrcHover='/images/customer/11_kpi_hover.webp'
            imgAlt='KPI'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/12_pilot.webp'
            imgSrcHover='/images/customer/12_pilot_hover.webp'
            imgAlt='Pilot'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/13_cdc.webp'
            imgSrcHover='/images/customer/13_cdc_hover.webp'
            imgAlt='CDC'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/14_thecliff.webp'
            imgSrcHover='/images/customer/14_thecliff_hover.webp'
            imgAlt='The Cliff'
            className='customer-item'
          />
          <CustomerLogo
            imgSrc='/images/customer/15_tomgna.webp'
            imgSrcHover='/images/customer/15_tomgna_hover.webp'
            imgAlt='Tongna'
            className='customer-item'
          />
        </div>
      </div>
    </div>
  )
}

export default MyCustomers
