/* eslint-disable @next/next/no-img-element */
import React, { FC, useEffect, useState, useContext } from 'react'
import useMediaQuery from '@/utils/useMediaQuery'
import Footer from '@/components/layouts/Footer'
import Header from '@/components/layouts/Header'
import { SeoHead, ImgResponsive } from '@/components/elements'
import Link from 'next/link'
import classNames from 'classnames'
import { WithChildren } from '@/types'
import { preloadProps } from '@/components/elements/SeoHead'
import { FaXmark } from 'react-icons/fa6'
import { StyleDrawer, StyleDrawerContent } from './style'
import { TransitionContext } from '@/context/TransitionContext'

interface LayoutDefaultProps extends WithChildren {
  title?: string
  description?: string
  url?: string
  image?: string
  type?: string
  publishDate?: string
  preload?: preloadProps[]
  background?: string
}

const LayoutDefault: FC<LayoutDefaultProps> = (props) => {
  const {
    background,
    children,
    title = 'Twin Synergy รับทำเว็บไซต์ และแอปพลิเคชัน',
    description = 'ที่ Twin Synergy เรามุ่งมั่นในการพัฒนาเว็บไซต์และแอปพลิเคชันที่ตอบโจทย์ธุรกิจของคุณ ด้วยทีมงานผู้เชี่ยวชาญและประสบการณ์ที่หลากหลาย ติดต่อได้ที่เบอร์ 063-7899059',
    url = process.env.WEBSITE_URL,
    image = 'https://twinsynergy.co.th/images/social-home.webp',
    type = 'website',
    publishDate,
    preload,
  } = props

  const { setBackground } = useContext(TransitionContext)
  const isBreakpoint = useMediaQuery(768)
  const [isActive, setIsActive] = useState(false)

  const backDropCls = classNames('drawer-overlay', {
    'drawer-overlay-open': isActive === true,
    'drawer-overlay-close': isActive === false,
  })

  const drawerCls = classNames('drawer', {
    'drawer-open': isActive === true,
    'drawer-close': isActive === false,
  })

  useEffect(() => {
    typeof window !== 'undefined' &&
      typeof setBackground === 'function' &&
      setBackground(background ?? 'white')
  }, [background, setBackground])

  return (
    <div style={{ background: background }}>
      <SeoHead
        title={title}
        description={description}
        url={url}
        image={image}
        type={type}
        publishDate={publishDate}
        preload={preload}
      />
      <Header isActive={isActive} setIsActive={() => setIsActive(!isActive)} />
      {children}
      <Footer />
      {isBreakpoint && isActive && (
        <StyleDrawer>
          <StyleDrawerContent className={drawerCls}>
            <div className='drawer-content'>
              <div className='drawer-content__header'>
                <div className='flex justify-between px-4'>
                  <Link href='/' className='drawer-content__logo-link'>
                    <ImgResponsive
                      src='/images/Logo-2020-Black.webp'
                      alt='Twin Synergy รับพัฒนาเว็บไซต์, แอปพลิเคชั่น และออกแบบเว็บไซต์'
                      className='drawer-content__logo-img'
                      width={198}
                      height={42}
                    />
                  </Link>
                  <button className='px-3.5' onClick={() => setIsActive(false)} aria-label='btn'>
                    <FaXmark />
                  </button>
                </div>
              </div>
              <ul className='menu'>
                <li className='header-temp__menu-item'>
                  <Link href='/'>Home</Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/services'>Services</Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/blog'>Article</Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/portfolio'>Portfolio</Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/contact'>Contact</Link>
                </li>
              </ul>
            </div>
          </StyleDrawerContent>
          {isBreakpoint && isActive && (
            <div
              aria-hidden='true'
              className={backDropCls}
              onClick={() => setIsActive(false)}
            ></div>
          )}
        </StyleDrawer>
      )}
    </div>
  )
}

LayoutDefault.propTypes = {}

export default LayoutDefault
