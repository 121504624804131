/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect'
import ListBullet from '../ListBullet'
import ImgResponsive from '@/components/elements/ImgResponsive'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const JourneyAward = () => {
  useIsomorphicLayoutEffect(() => {
    // intro animations
    // ref is an object with a current property that holds the DOM element
    const title = '.journey-award-title'
    const listBullet = '.list-bullet'
    const trophyContent = '.trophy-content'
    const trophyItem = '.trophy-item'
    const duration = 0.5
    const translateX = '-100px'
    const initialOpacity = 0
    const initialX = -20
    const startTrigger = '-70px center'
    const endTrigger = 'top center'
    // title
    const journeyAward = gsap.timeline({
      scrollTrigger: {
        trigger: title,
        start: '-60px center',
        end: endTrigger,
        // markers: true,
      },
    })
    journeyAward
      .addLabel('start')
      .from(title, { opacity: initialOpacity, translateX })
      .addLabel('entering')
      .to(title, { opacity: 1, translateX: '0px', duration })
      .addLabel('end')
    gsap.set(listBullet, { opacity: initialOpacity, x: initialX })
    ScrollTrigger.batch(listBullet, {
      start: startTrigger,
      end: endTrigger,
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, x: 0, autoAlpha: 1, stagger: 0.15 }),
    })

    // trophy
    const trophy = gsap.timeline({
      scrollTrigger: {
        trigger: trophyContent,
        start: '-60px center',
        end: endTrigger,
        // markers: true,
      },
    })
    trophy
      .addLabel('start')
      .from(trophyContent, { opacity: 0, translateX: '-100px' })
      .addLabel('entering')
      .to(trophyContent, { opacity: 1, translateX: '0px', duration: 0.5 })
      .addLabel('end')
    gsap.set(trophyItem, { opacity: 0, x: -20 })
    ScrollTrigger.batch(trophyItem, {
      start: startTrigger,
      end: endTrigger,
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, x: 0, autoAlpha: 1, stagger: 0.15 }),
    })
  }, [])

  return (
    <div className='section-journey bg-neutral py-24'>
      <div className='journey-award container mx-auto max-w-screen-3xl px-6'>
        <div className='grid grid-cols-1 items-center gap-4 lg:grid-cols-5'>
          <div className='col-span-2 text-white'>
            <span className='text-sm uppercase '>My Awards</span>
            <h3 className='journey-award-title mb-8 text-3xl xl:text-5xl'>รางวัล</h3>
            <div className='journey-content'>
              <ListBullet
                list={[
                  'The Winner of THAILAND ICT AWARD 2015 (E-Community and E-Inclusions)',
                  'The 1st Runner Up of THAILAND ICT AWARD 2016 (Start Up)',
                  'The 1st Runner Up of THAILAND ICT AWARD 2016 (E-Community and E-Inclusions)',
                  '1 ใน 10 รางวัลเจ้าฟ้าไอที รัตนราชสุดาสารสนเทศ ครั้งที่ 11 ปี พ.ศ.2559',
                  `ได้รับทุน 2,000,000 บาท จากสำนักงานการวิจัยแห่งชาติ ในงบประมาณประจำปี พ.ศ.
                      2562`,
                ]}
              />
            </div>
          </div>
          <div className='trophy-content col-span-3 grid w-full grid-cols-1 items-end gap-x-8 md:grid-cols-3'>
            <span className='trophy-item'>
              <ImgResponsive
                className='move'
                src='/images/home/award_03.webp'
                alt='trophy 1'
                width={281}
                height={484}
                loadingLazy
              />
            </span>
            <span className='trophy-item'>
              <ImgResponsive
                className='move'
                src='/images/home/award_04.webp'
                alt='trophy 2'
                width={317}
                height={369}
                loadingLazy
              />
            </span>
            <span className='trophy-item'>
              <ImgResponsive
                className='move'
                src='/images/home/award_05.webp'
                alt='trophy 3'
                width={315}
                height={344}
                loadingLazy
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JourneyAward
